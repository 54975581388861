<template>
  <b-modal
    v-model="modalShow"
    title="Change User Password"
    title-tag="h3"
    centered
    @ok.prevent="changePassword()"
    cancel-variant="outline-danger"
    size="lg"
    @hidden="close"
    id="modalSNEditTask"
    no-close-on-backdrop
  >
    <div>
        <ValidationObserver ref="form">
            <b-row>
                <b-col cols="12">
                    <b-form-group label="New Password" label-for="new-password">
                        <ValidationProvider
                            v-slot="{ errors, valid }"
                            rules="required|specialpassword|password:@confirm"
                        >
                            <b-input-group>
                                <b-form-input
                                    id="new-password"
                                    v-model="userData.newPassword"
                                    :type="isWatch ? 'text' : 'password'"
                                    placeholder="New Password"
                                    :state="errors[0] ? false : valid ? true : null"
                                />
                                <b-input-group-append class="border-style" is-text>
                                    <feather-icon
                                    :icon="!isWatch ? 'EyeIcon' : 'EyeOffIcon'"
                                    class="cursor-pointer"
                                    @click="isWatch = !isWatch"
                                    />
                                </b-input-group-append>
                                <b-form-invalid-feedback>
                                    New Password {{ errors[0] }}
                                </b-form-invalid-feedback>
                            </b-input-group>
                        </ValidationProvider>
                    </b-form-group>
                </b-col>
                <b-col cols="12">
                    <b-form-group label="Confirm Password" label-for="confirm-password">
                        <ValidationProvider
                            v-slot="{ errors, valid }"
                            rules="required"
                            name="confirm"
                        >
                            <b-input-group>
                                <b-form-input
                                    id="confirm-password"
                                    v-model="userData.confirmPassword"
                                    :type="isWatchConfirm ? 'text' : 'password'"
                                    placeholder="Confirm Password"
                                    :state="errors[0] ? false : valid ? true : null"
                                />
                                <b-input-group-append class="border-style" is-text>
                                    <feather-icon
                                    :icon="!isWatchConfirm ? 'EyeIcon' : 'EyeOffIcon'"
                                    class="cursor-pointer"
                                    @click="isWatchConfirm = !isWatchConfirm"
                                    />
                                </b-input-group-append>
                                <b-form-invalid-feedback>
                                    Confirm Password {{ errors[0] }}
                                </b-form-invalid-feedback>
                            </b-input-group>
                        </ValidationProvider>
                    </b-form-group>
                </b-col>
            </b-row>
        </ValidationObserver>
    </div>
  </b-modal>
</template>

<script>
import EditService from "../services/edit-user.service";
export default {
    props: {
        show: {
            type: Boolean,
            default: false
        },
        userId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            modalShow: this.show,
            isWatch: false,
            isWatchConfirm: false,
            userData: {
                id_user: parseInt(this.userId),
                newPassword: "",
                confirmPassword: "",
            },
        };
    },
    methods: {
        async changePassword() {
            const result = await this.$refs.form.validate();
            if (result) {
                this.addPreloader();
                try {
                    const changePass = await EditService.changePasswordUser({
                        id_user: this.userData.id_user,
                        new_password: this.userData.newPassword,
                    });
                    this.removePreloader();
                    this.showSuccessSwal("Password changed successfully");
                    this.$emit("onClose");
                } catch (error) {
                    this.removePreloader();
                    this.showErrorSwal(error);
                }
            } else {
                console.log("error");
            }
        },
        close() {
            this.$emit("onClose");
        },
    }
}
</script>

<style scoped>
.border-style{
    border-right-style: solid;
    border-color: rgba(255, 255, 255, 0.4);
    border-width: 1px;
}
</style>