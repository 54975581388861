<template>
  <div>
    <ValidationObserver ref="form">
      <b-row>
        <b-col cols="12">
          <b-card title="User Information">
            <b-row class="m-0 p-0">
              <b-row class="w-100 m-0 p-0">
                <!-- User Info: Left col -->
                <b-col
                  cols="12"
                  xl="4"
                  md="12"
                  class="d-flex justify-content-between flex-column mb-lg-0 mb-md-3 mb-md-3 mb-sm-3 mb-3 gap-5"
                >
                  <!-- User Avatar & Action Buttons -->
                  <div class="d-flex justify-content-start">
                    <div
                      v-if="form.file != ''"
                      :class="['avatar-container', { backdrop: form.remove }]"
                    >
                      <div
                        v-if="form.remove"
                        class="remove-temporal-avatar"
                        @click="clearFileData"
                      >
                        <div>
                          <feather-icon icon="XIcon" size="35" />
                        </div>
                      </div>
                      <b-avatar
                        ref="previewEl"
                        :src="form.file"
                        :text="avatarText(userData.fullName)"
                        size="130px"
                        rounded
                      />
                    </div>
                    <b-avatar
                      v-else
                      :src="userData.avatar"
                      :text="avatarText(userData.fullName)"
                      :variant="`light-primary`"
                      size="130px"
                      rounded
                    />
                    <div class="ml-2">
                      <div>
                        <h4 class="mb-0">
                          <b>
                            {{ userData.fullName }}
                          </b>
                        </h4>
                        <span class="card-text">{{ userData.email }}</span>
                      </div>

                      <b-button
                        variant="outline-primary"
                        size="sm"
                        @click="addFile"
                      >
                        <b-form-file
                          ref="refInputEl"
                          class="d-none"
                          @change="fillFileData"
                        />
                        <feather-icon icon="ImageIcon" class="d-inline mr-25" />
                        <span class="d-none d-sm-inline">Upload</span>
                      </b-button>
                    </div>
                  </div>
                </b-col>

                <!-- Right Col: Table -->

                <b-col
                  cols="12"
                  xl="4"
                  md="6"
                  class="information-form d-flex flex-column"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="type-document"
                    rules="required"
                  >
                    <b-form-group
                      label-cols-lg="3"
                      label-cols-md="12"
                      label-cols-sm="12"
                      label-cols="12"
                      content-cols-lg="8"
                      content-cols-md="12"
                      content-cols-sm="12"
                      content-cols="12"
                    >
                      <template #label>
                        <feather-icon icon="LogInIcon" class="mr-75" />
                        Type document
                      </template>
                      <v-select
                        v-model="userData.pid_type"
                        :clearable="false"
                        :options="optionsTypeDocument"
                        :reduce="(option) => option.id"
                        label="name"
                        placeholder="--Select option--"
                        :class="errors[0] ? 'border-danger rounded' : ''"
                        :disabled="$route.meta.tittleBtn === 'Update'"
                      />
                    </b-form-group>
                  </validation-provider>
                  <validation-provider v-slot="{ errors }" name="DNI">
                    <b-form-group
                      label-cols-lg="3"
                      label-cols-md="12"
                      label-cols-sm="12"
                      label-cols="12"
                      content-cols-lg="8"
                      content-cols-md="12"
                      content-cols-sm="12"
                      content-cols="12"
                    >
                      <template #label>
                        <feather-icon icon="LogInIcon" class="mr-75" />
                        {{ userData.pid_type === 1 ? "DNI" : "N Doc." }}
                      </template>
                      <div class="d-flex">
                        <template v-if="userData.pid_type === 1">
                          <b-form-input
                            v-model="userData.pid"
                            v-mask="'########'"
                            placeholder="DNI"
                            :state="errors[0] ? false : null"
                            :disabled="$route.meta.tittleBtn === 'Update'"
                            @keyup.enter="validateByDNI()"
                          />
                          <b-button
                            variant="outline-primary"
                            size="sm ml-1"
                            :hidden="$route.meta.tittleBtn === 'Update'"
                            @click="validateByDNI()"
                          >
                            <feather-icon icon="SearchIcon" />
                          </b-button>
                        </template>

                        <b-form-input
                          v-else
                          v-model="userData.pid"
                          v-mask="'######################'"
                          placeholder="N Document"
                          :state="errors[0] ? false : null"
                          @keyup.enter="validateByDNI()"
                        />
                      </div>
                    </b-form-group>
                  </validation-provider>

                  <ValidationProvider
                    v-slot="{ errors }"
                    name="first_name"
                    rules="required|max:100"
                  >
                    <b-form-group
                      label-cols-lg="3"
                      label-cols-md="12"
                      label-cols-sm="12"
                      label-cols="12"
                      content-cols-lg="8"
                      content-cols-md="12"
                      content-cols-sm="12"
                      content-cols="12"
                    >
                      <template #label>
                        <feather-icon icon="UserIcon" class="mr-75" />
                        First Name
                      </template>
                      <b-form-input
                        v-model="userData.first_name"
                        :state="errors[0] ? false : null"
                        :class="{ 'is-border-danger': errors[0] }"
                      />
                    </b-form-group>
                  </ValidationProvider>

                  <ValidationProvider
                    v-slot="{ errors }"
                    name="last_name"
                    rules="required|max:100"
                  >
                    <b-form-group
                      label-cols-lg="3"
                      label-cols-md="12"
                      label-cols-sm="12"
                      label-cols="12"
                      content-cols-lg="8"
                      content-cols-md="12"
                      content-cols-sm="12"
                      content-cols="12"
                    >
                      <template #label>
                        <feather-icon icon="UserIcon" class="mr-75" />
                        Last Name
                      </template>

                      <b-form-input
                        v-model="userData.last_name"
                        :maxlength="50"
                        :state="errors[0] ? false : null"
                        :class="{ 'is-border-danger': errors[0] }"
                      />
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col
                  cols="12"
                  xl="4"
                  md="6"
                  class="information-form d-flex flex-column text-left w-100"
                >
                  <ValidationProvider v-slot="{ errors }" name="phone">
                    <b-form-group
                      label-cols-lg="4"
                      label-cols-md="12"
                      label-cols-sm="12"
                      label-cols="12"
                      content-cols-lg="8"
                      content-cols-md="12"
                      content-cols-sm="12"
                      content-cols="12"
                    >
                      <template #label>
                        <feather-icon icon="StarIcon" class="mr-75" />
                        Phone
                      </template>

                      <b-form-input
                        v-model="userData.phone"
                        v-mask="'(XXX)-XXX-XXXX'"
                        :state="errors[0] ? false : null"
                        :class="{ 'is-border-danger': errors[0] }"
                      />
                    </b-form-group>
                  </ValidationProvider>
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="gender"
                    rules="required"
                  >
                    <b-form-group
                      label-cols-lg="4"
                      label-cols-md="12"
                      label-cols-sm="12"
                      label-cols="12"
                      content-cols-lg="8"
                      content-cols-md="12"
                      content-cols-sm="12"
                      content-cols="12"
                    >
                      <template #label>
                        <feather-icon icon="StarIcon" class="mr-75" />
                        Gender
                      </template>

                      <b-select
                        v-model="userData.gender"
                        value-field="id"
                        text-field="gender"
                        :options="gender"
                        :state="errors[0] ? false : null"
                      />
                    </b-form-group>
                  </ValidationProvider>
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="start_date"
                    rules="required"
                  >
                    <b-form-group
                      label-cols-lg="4"
                      label-cols-md="12"
                      label-cols-sm="12"
                      label-cols="12"
                      content-cols-lg="8"
                      content-cols-md="12"
                      content-cols-sm="12"
                      content-cols="12"
                    >
                      <template #label>
                        <feather-icon icon="CalendarIcon" class="mr-75" />
                        Start Date
                      </template>
                      <kendo-datepicker
                        v-model="userData.start_date"
                        :state="errors[0] ? false : null"
                        locale="en"
                        :class="{ 'is-border-danger': errors[0] }"
                        style="width: 100% !important"
                      />
                    </b-form-group>
                  </ValidationProvider>

                  <b-form-group
                    label-cols-lg="4"
                    label-cols-md="12"
                    label-cols-sm="12"
                    label-cols="12"
                    content-cols-lg="8"
                    content-cols-md="12"
                    content-cols-sm="12"
                    content-cols="12"
                  >
                    <template #label>
                      <feather-icon icon="FlagIcon" class="mr-75" />
                      Country
                    </template>
                    <b-form-radio-group
                      v-model="userData.country"
                      class="text-danger"
                      style="margin-top: 6px"
                      :options="optionsCountries"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" lg="10">
          <b-card title="User Access">
            <b-container class="mx-1 pr-3 my-1">
              <b-row>
                <b-col cols="12" xl="5" md="12" class="information-form">
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="email"
                    rules="required|specialemail"
                  >
                    <b-form-group
                      label-cols-lg="3"
                      label-cols-md="3"
                      label-cols-sm="3"
                      label-cols="12"
                      content-cols-lg="9"
                      content-cols-md="9"
                      content-cols-sm="9"
                      content-cols="12"
                    >
                      <template #label> Email (User Name) </template>
                      <b-form-input
                        v-model="userData.email"
                        :state="errors[0] ? false : null"
                        :class="{
                          'is-border-danger': errors[0] || isValidEmail,
                        }"
                      />
                      <b-form-invalid-feedback>
                        <span v-if="errors[0]">New Email {{ errors[0] }}</span>
                      </b-form-invalid-feedback>
                      <span v-if="isValidEmail" class="text-danger"
                        >Email already exists</span
                      >
                    </b-form-group>
                    <!-- <pre>
                      {{ currentUser.user_id }}
                      {{ moduleId }}
                    </pre> -->
                  </ValidationProvider>

                  <b-form-group
                    v-if="
                      (userId &&
                        (currentUser.user_id === 1 ||
                          currentUser.user_id === 41 ||
                          currentUser.user_id === 347) &&
                        moduleId !== 1) ||
                      (!userId && moduleId === 12) ||
                      moduleId === 1 ||
                      moduleId === 17 ||
                      moduleId === 16
                    "
                    label-cols-lg="3"
                    label-cols-md="3"
                    label-cols-sm="3"
                    label-cols="12"
                    content-cols-lg="9"
                    content-cols-md="9"
                    content-cols-sm="9"
                    content-cols="12"
                  >
                    <template #label> Password </template>
                    <div v-if="userId" class="d-flex justify-content-around">
                      <b-button
                        variant="outline-primary"
                        @click="openModalValidatePass = true"
                        >Change Password</b-button
                      >
                      <b-button
                        v-if="showPasswordPublic === false"
                        variant="outline-success"
                        @click="openModalValidateShow = true"
                        >Show Password</b-button
                      >
                      <span v-else class="text-center">
                        Password User: {{ getPasswordEditUser }}</span
                      >
                    </div>
                    <div v-else>
                      <ValidationProvider
                        v-slot="{ errors }"
                        name="password"
                        rules="required|specialpassword"
                      >
                        <b-form-input
                          v-model="userData.password"
                          type="password"
                          :state="errors[0] ? false : null"
                          :class="{ 'is-border-danger': errors[0] }"
                        />
                        <b-form-invalid-feedback>
                          New Password: {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </ValidationProvider>
                    </div>
                  </b-form-group>

                  <b-form-group>
                    <template #label>
                      <span class="d-inline">Sale Permission</span>
                      <b-form-checkbox
                        v-model="allSelected"
                        class="ml-2 d-inline"
                        @change="toggleAll"
                      >
                        All
                      </b-form-checkbox>
                    </template>

                    <div class="mt-1 checks-boxes">
                      <b-form-checkbox
                        v-for="option in programsOptions"
                        :key="option.id"
                        v-model="userData.program"
                        :value="{ id: option.id }"
                      >
                        <span style="font-size: 12px">{{ option.name }}</span>
                      </b-form-checkbox>
                    </div>
                  </b-form-group>
                </b-col>
                <b-col
                  cols="12"
                  xl="7"
                  md="12"
                  class="information-form d-flex text-center flex-column"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="department"
                    rules="required"
                  >
                    <b-form-group
                      label-cols-lg="3"
                      label-cols-md="12"
                      label-cols-sm="12"
                      label-cols="12"
                      content-cols-lg="8"
                      content-cols-md="12"
                      content-cols-sm="12"
                      content-cols="12"
                    >
                      <template #label>
                        <feather-icon icon="StarIcon" class="mr-75" />
                        Department (*)
                      </template>

                      <v-select
                        v-model="userData.department_id"
                        label="name"
                        :reduce="(val) => val.id"
                        :options="department"
                        :state="errors[0] ? false : null"
                        :class="{ 'is-border-danger': errors[0] }"
                        @input="getRolByDepartment"
                      />
                    </b-form-group>
                  </ValidationProvider>
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="roles"
                    rules="required"
                  >
                    <b-form-group
                      label-cols-lg="3"
                      label-cols-md="12"
                      label-cols-sm="12"
                      label-cols="12"
                      content-cols-lg="8"
                      content-cols-md="12"
                      content-cols-sm="12"
                      content-cols="12"
                    >
                      <template #label>
                        <feather-icon icon="StarIcon" class="mr-75" />
                        Role by department (*)
                      </template>

                      <v-select
                        v-model="userData.rol_id"
                        label="name"
                        :reduce="(val) => val.id"
                        :options="optionsRol"
                        :state="errors[0] ? false : null"
                        :class="{ 'is-border-danger': errors[0] }"
                      />
                    </b-form-group>
                  </ValidationProvider>
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="admin"
                    rules="required"
                  >
                    <b-form-group
                      style="margin-bottom: 0"
                      label-cols-lg="4"
                      label-cols-md="5"
                      label-cols-sm="4"
                      label-cols="12"
                      content-cols-lg="7"
                      content-cols-md="7"
                      content-cols-sm="8"
                      content-cols="12"
                    >
                      <template #label> Roles </template>
                      <b-form-radio-group
                        v-model="userData.admin"
                        class="text-danger d-flex justify-content-around"
                        style="margin-top: 6px"
                        :class="errors[0] ? 'border-danger' : ''"
                        :options="optionsRoles"
                      />
                    </b-form-group>
                  </ValidationProvider>

                  <b-form-group
                    v-if="userData.admin == 2"
                    content-cols="12"
                    style="margin-bottom: 0"
                  >
                    <ValidationProvider v-slot="{ errors }" rules="required">
                      <b-row>
                        <b-col cols="4">
                          <b-form-group
                            label-cols-lg="6"
                            label-cols-md="4"
                            label-cols-sm="4"
                            label-cols="6"
                            content-cols-lg="12"
                            content-cols-md="6"
                            content-cols-sm="6"
                            content-cols="12"
                          >
                            <template #label> Module </template>
                            <v-select
                              v-model="itemArr.module"
                              label="name"
                              :options="modules"
                              :clearable="false"
                              :class="isModuleRole ? 'border-danger' : ''"
                              :disabled="itemArr.index !== null"
                              :reduce="(option) => option"
                              :state="errors[0] ? false : null"
                            >
                              <template #option="{ name }">
                                <span class="ml-50">{{ name }}</span>
                              </template>
                              <template #selected-option="{ name }">
                                <span class="ml-50">{{ name }}</span>
                              </template>
                            </v-select>
                          </b-form-group>
                        </b-col>
                        <b-col v-if="numberChildren === 0" cols="4">
                          <b-form-group
                            label-cols-lg="6"
                            label-cols-md="4"
                            label-cols-sm="4"
                            label-cols="6"
                            content-cols-lg="12"
                            content-cols-md="6"
                            content-cols-sm="6"
                            content-cols="12"
                          >
                            <template #label> Role </template>
                            <v-select
                              v-model="itemArr.role"
                              label="name"
                              :clearable="false"
                              :class="isModuleRole ? 'border-danger' : ''"
                              :options="roles.filter((rol) => rol.id != 1)"
                              :reduce="(option) => option"
                              @change="
                                userData.skill = {
                                  id: 0,
                                  name: 'Select a skill',
                                }
                              "
                            >
                              <template #option="{ name }">
                                <span class="ml-50">{{ name }}</span>
                              </template>
                              <template #selected-option="{ name }">
                                <span class="ml-50">{{ name }}</span>
                              </template>
                            </v-select>
                          </b-form-group>
                        </b-col>
                        <b-col
                          v-if="numberChildren > 0 && itemArr.module.id === 21"
                          cols="12"
                          xl="7"
                          md="12"
                          class="information-form"
                        >
                          <b-form-group
                            style="margin-bottom: 0"
                            content-cols="12"
                          >
                            <b-form-radio-group
                              v-model="userData.creditExpertAdmin"
                              class="text-danger d-flex justify-content-around align-items-center"
                              style="margin-top: 20px"
                              :options="creditExpertsOptionsRoles"
                              @change="changeCreditExpertAdmin"
                            />
                          </b-form-group>
                        </b-col>

                        <b-col
                          cols="12"
                          xl="7"
                          md="12"
                          class="information-form"
                          v-if="numberChildren > 0 && itemArr.module.id === 11"
                        >
                          <b-form-group
                            style="margin-bottom: 0"
                            content-cols="12"
                          >
                            <b-form-radio-group
                              class="text-danger d-flex justify-content-around align-items-center"
                              style="margin-top: 20px"
                              v-model="userData.creditExpertAdmin"
                              @change="changeCreditExpertAdmin"
                              :options="specialistsOptionsRoles"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col
                          cols="1"
                          style="margin-top: 1.3rem"
                          class="d-flex justify-content-center align-items-center"
                        >
                          <b-button
                            variant="primary"
                            size="sm"
                            @click="addRelation(itemArr)"
                          >
                            <feather-icon icon="PlusIcon" size="18" />
                          </b-button>
                        </b-col>
                        <template v-if="userData.creditExpertAdmin === 2">
                          <b-col v-if="numberChildren > 0" cols="4">
                            <b-form-group
                              label-cols-lg="6"
                              label-cols-md="4"
                              label-cols-sm="4"
                              label-cols="6"
                              content-cols-lg="12"
                              content-cols-md="6"
                              content-cols-sm="6"
                              content-cols="12"
                            >
                              <template #label> Sub Module </template>
                              <v-select
                                v-model="itemArr.sub_module"
                                label="name"
                                :options="subModules"
                                :clearable="false"
                                :class="isModuleRole ? 'border-danger' : ''"
                                :disabled="itemArr.index !== null"
                                :reduce="(option) => option"
                              >
                                <template #option="{ name }">
                                  <span class="ml-50">{{ name }}</span>
                                </template>
                                <template #selected-option="{ name }">
                                  <span class="ml-50">{{ name }}</span>
                                </template>
                              </v-select>
                            </b-form-group>
                          </b-col>
                          <b-col v-if="numberChildren > 0" cols="4">
                            <b-form-group
                              label-cols-lg="6"
                              label-cols-md="4"
                              label-cols-sm="4"
                              label-cols="6"
                              content-cols-lg="12"
                              content-cols-md="6"
                              content-cols-sm="6"
                              content-cols="12"
                            >
                              <template #label> Role </template>
                              <v-select
                                v-model="itemArr.role"
                                label="name"
                                :clearable="false"
                                :class="isModuleRole ? 'border-danger' : ''"
                                :options="
                                  roles.filter(
                                    (rol) => rol.id != 1 && rol.id != 17
                                  )
                                "
                                :disabled="isDisabledSubModuleRol"
                                :reduce="(option) => option"
                                @change="
                                  userData.skill = {
                                    id: 0,
                                    name: 'Select a skill',
                                  }
                                "
                              >
                                <template #option="{ name }">
                                  <span class="ml-50">{{ name }}</span>
                                </template>
                                <template #selected-option="{ name }">
                                  <span class="ml-50">{{ name }}</span>
                                </template>
                              </v-select>
                            </b-form-group>
                          </b-col>
                          <b-col
                            v-if="numberChildren > 0 && itemArr.role.id === 15"
                            cols="4"
                          >
                            <b-form-group
                              label-cols-lg="6"
                              label-cols-md="4"
                              label-cols-sm="4"
                              label-cols="6"
                              content-cols-lg="12"
                              content-cols-md="6"
                              content-cols-sm="6"
                              content-cols="12"
                            >
                              <template #label> Skill </template>
                              <v-select
                                v-model="userData.skill"
                                :clearable="false"
                                label="name"
                                :class="isModuleRole ? 'border-danger' : ''"
                                :options="[
                                  { id: 1, name: 'Monolingual' },
                                  { id: 2, name: 'Bilingual' },
                                ]"
                                :reduce="(option) => option"
                              >
                                <template #option="{ name }">
                                  <span class="ml-50">{{ name }}</span>
                                </template>
                                <template #selected-option="{ name }">
                                  <span class="ml-50">{{ name }}</span>
                                </template>
                              </v-select>
                            </b-form-group>
                          </b-col>
                        </template>
                        <b-col
                          v-if="
                            (itemArr.sub_module.id === 26 &&
                              itemArr.role.id === 5) ||
                            (itemArr.module.id === 2 &&
                              itemArr.role.id === 5) ||
                            (itemArr.module.id === 15 &&
                              itemArr.role.id === 9) ||
                            itemArr.role.id === 10 ||
                            (itemArr.sub_module.id === 20 &&
                              itemArr.role.id === 15)
                          "
                          cols="8"
                          >newar
                          <b-form-group style="margin-bottom: 0">
                            <b-form-radio-group
                              v-model="itemArr.typesenior"
                              class="d-flex justify-content-around"
                              style="margin-top: 6px"
                              :options="optionsTypeSenior"
                              :class="{ 'border-danger rounded': isModuleRole }"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col
                          v-if="
                            itemArr.module.id === 2 && itemArr.role.id === 5
                          "
                          cols="4"
                        >
                          <b-form-checkbox
                            v-model="itemArr.social_network"
                            style="margin-top: 6px"
                            >Social Network</b-form-checkbox
                          >
                        </b-col>
                      </b-row>
                    </ValidationProvider></b-form-group
                  >

                  <b-form-group
                    v-if="userData.admin == 2"
                    content-cols="12"
                    style="margin-bottom: 0"
                  >
                    <b-table
                      ref="refRolesTable"
                      class="position-relative"
                      :items="userData.newarr"
                      :fields="fields"
                      table-class="text-nowrap"
                      responsive="sm"
                      show-empty
                      no-provider-filtering
                      sticky-header="35vh"
                      small
                    >
                      <template #cell(role)="data">
                        <span v-if="data.item.role !== 'Seller'">
                          <span> {{ data.value }} </span>
                          <span
                            v-if="
                              data.item.parent_id === 21 &&
                              data.item.id_role === 15
                            "
                          >
                            {{
                              userData.skill.id === 1
                                ? "Monolingual"
                                : "Bilingual"
                            }}
                            {{
                              formatTypeSenior(
                                data.item.typesenior,
                                data.item.id_module === 20
                              )
                            }}
                          </span>
                        </span>
                        <span v-else>
                          {{
                            data.value +
                            " " +
                            (data.item.typesenior == 1
                              ? " Trainer"
                              : data.item.typesenior == 2
                              ? " Junior"
                              : data.item.typesenior === 3
                              ? "Senior"
                              : data.item.typesenior === 4
                              ? "Master"
                              : "")
                          }}
                        </span>
                      </template>
                      <template #cell(sn)="data">
                        <feather-icon
                          v-if="
                            data.item.role === 'Seller' &&
                            userData.social_network == 1
                          "
                          style="color: green"
                          icon="CheckIcon"
                          size="18"
                        />
                        <span
                          v-else-if="
                            data.item.role != 'Seller' ||
                            (data.item.role == 'Seller' &&
                              userData.social_network == 0)
                          "
                        >
                          -
                        </span>
                      </template>
                      <template #cell(main)="data">
                        <b-form-radio
                          v-model="userData.mainmodule"
                          class="text-danger"
                          :value="data.item.id_module"
                        />
                      </template>
                      <template #cell(action)="data">
                        <div class="d-flex justify-content-around">
                          <feather-icon
                            style="color: red; cursor: pointer"
                            icon="Trash2Icon"
                            size="18"
                            @click="deleteRelation(data.index)"
                          />
                        </div>
                      </template>
                    </b-table>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-container>
          </b-card>
        </b-col>
        <b-col cols="12" lg="2" class="d-flex">
          <b-card class="w-100 d-flex justify-content-center" title="Others">
            <b-form-group style="height: 50%" class="d-flex">
              <b-form-checkbox
                v-model="userData.restriction_by_ip"
                value="1"
                unchecked-value="0"
                class="mb-1"
                >This user has IP restriction</b-form-checkbox
              >
              <b-form-checkbox
                v-model="userData.status"
                value="1"
                unchecked-value="0"
                class="mb-1"
                @change="checkIfActiveOrInactive"
                >Active</b-form-checkbox
              >
              <b-form-checkbox
                v-model="userData.ticket_reviewer"
                value="1"
                unchecked-value="0"
                class="mb-1"
                >Set Ticket Reviewer</b-form-checkbox
              >
            </b-form-group>
          </b-card>
        </b-col>
      </b-row>
    </ValidationObserver>

    <validate-password-ceo
      v-if="openModalValidatePass && userId"
      :show="openModalValidatePass"
      :user-id="userId"
      :super-id="userData.superid"
      @onClose="closeModalValidatePass"
      @doSomething="showModalPassAfterValidate"
    />
    <change-user-password
      v-if="openModalChange && userId"
      :show="openModalChange"
      :user-id="userId"
      @onClose="closeModalChange"
    />
    <validate-password-ceo
      v-if="openModalValidateShow && userId"
      :show="openModalValidateShow"
      :user-id="userId"
      :super-id="userData.superid"
      @onClose="closeModalValidateShow"
      @doSomething="showPasswordUser"
    />
    <modal-clients-assigned
      v-if="modalClientsAssignedState"
      :data-item="dataItem"
      :from-users-module="true"
      :module-id="userData.main_module_id"
      @hideModal="modalClientsAssignedState = false"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import vSelect from "vue-select";
import { amgApi } from "@/service/axios";
import { avatarText } from "@core/utils/filter";
import EmployeesService from "@/views/management/views/employees/employees.service";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ModalClientsAssigned from "@/views/ce-digital/sub-modules/settings/views/users/view/components/ModalClientsAssigned.vue";
import ValidatePasswordCeo from "@/views/users/commons/users/user-view/ValidatePasswordCeo.vue";
import ChangeUserPassword from "@/views/users/commons/users/user-view/ChangePassword.vue";
import UsersService from "@/views/ce-digital/sub-modules/settings/views/users/service/users.service";
import { swalWarningIcon } from "@/icons/statusIcons";
import EditService from "../services/edit-user.service";

export default {
  components: {
    vSelect,
    ChangeUserPassword,
    ValidatePasswordCeo,
    ModalClientsAssigned,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
    form: {
      type: Object,
    },
    modules: {
      type: Array,
      required: true,
    },
    programsOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      dni: null,
      gender: [
        { id: 1, gender: "Female" },
        { id: 2, gender: "Male" },
      ],
      optionsCountries: [
        { value: "PE", text: "PERU" },
        { value: "US", text: "UNITED STATES" },
      ],
      optionsRoles: [
        { value: 1, text: "CEO" },
        { value: 2, text: "Others" },
      ],
      creditExpertsOptionsRoles: [
        { value: 1, text: "Chief", disabled: false },
        { value: 2, text: "Others", disabled: false },
      ],
      specialistsOptionsRoles: [
        { value: 1, text: "Chief", disabled: false },
        { value: 2, text: "Others", disabled: false },
      ],
      optionsType: [
        { value: 1, text: "Trainer" },
        { value: 2, text: "Junior" },
        { value: 3, text: "Senior" },
        { value: 4, text: "Master" },
      ],
      fields: [
        { key: "module", label: "Module" },
        { key: "role", label: "Role" },
        { key: "sn", label: "SN" },
        { key: "main", label: "Main" },
        { key: "action", label: "Action" },
      ],
      roles: [],
      subModules: [],
      allSelected: false,
      isModuleRole: false,
      modalClientsAssignedState: false,
      itemArr: {
        role: { id: 0, name: "Select a role" },
        module: { id: 0, name: "Select a module" },
        typesenior: 0,
        social_network: 0,
        index: null,
        sub_module: { id: 0, name: "Select a sub module" },
      },
      isValidEmail: false,
      isNoElement: false,
      ruleAdmin: "module-required",
      openModalChange: false,
      openModalValidatePass: false,
      openModalValidateShow: false,
      showPasswordPublic: false,
      getPasswordEditUser: "",
      dataItem: [],
      numberChildren: 0,

      department: [],
      document: [],
      optionsRol: [],
      finishedLoading: false,

      dniNumber: "",

      optionsMethod: [
        { method: 1, name: "Transfer" },
        { method: 2, name: "Deposit" },
        { method: 3, name: "Cash" },
        { method: 4, name: "Check" },
      ],
      currentUserActual: null,
      optionsTypeDocument: [],
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      CounterEmployee: "EmployeeClaimsStore/G_COUNTER_EMPLOYEE_CLAIMS",
    }),
    userId() {
      return this.$route.params.id;
    },
    module() {
      return this.itemArr.module;
    },
    subModule() {
      return this.itemArr.sub_module;
    },
    optionsTypeSenior() {
      return this.optionsType.filter((item) =>
        this.itemArr.role.id === 10 ? item.value !== 1 : item
      );
    },
    containsChiefCreditExpertsDigital() {
      return (
        this.userData.newarr.filter(
          (item) => item.id_module === 21 && item.id_role === 17
        ).length > 0
      );
    },

    containsChiefSpecialists() {
      return (
        this.userData.newarr.filter(
          (item) => item.id_module === 11 && item.id_role === 17
        ).length > 0
      );
    },
    containsAChildOfCreditExpertsDigital() {
      return (
        this.userData.newarr.filter((item) => item.parent_id === 21).length > 0
      );
    },
    containsAChildOfSpecialists() {
      return (
        this.userData.newarr.filter((item) => item.parent_id === 11).length > 0
      );
    },
    isDisabledSubModuleRol() {
      return this.itemArr.sub_module.id == 0;
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
  },
  watch: {
    containsChiefCreditExpertsDigital(mewVal) {
      if (mewVal) {
        this.creditExpertsOptionsRoles[1].disabled = true;
      } else {
        this.creditExpertsOptionsRoles[1].disabled = false;
      }
    },
    containsAChildOfCreditExpertsDigital(mewVal) {
      if (mewVal) {
        this.creditExpertsOptionsRoles[0].disabled = true;
      } else {
        this.creditExpertsOptionsRoles[0].disabled = false;
      }
    },

    containsChiefSpecialists(mewVal) {
      if (mewVal) {
        this.specialistsOptionsRoles[1].disabled = true;
      } else {
        this.specialistsOptionsRoles[1].disabled = false;
      }
    },
    containsAChildOfSpecialists(mewVal) {
      if (mewVal) {
        this.specialistsOptionsRoles[0].disabled = true;
      } else {
        this.specialistsOptionsRoles[0].disabled = false;
      }
    },

    async module(newVal) {
      this.itemArr.index === null
        ? (this.itemArr.role = {
            id: 0,
            name: "Select a role",
          })
        : this.itemArr.role;
      if (newVal.id === 21) {
        this.itemArr.role.id = 17;
        this.itemArr.role.name = "Chief";
      }
      if (newVal.id === 11) {
        this.itemArr.role.id = 17;
        this.itemArr.role.name = "Chief";
      }
      await this.getModuleNumberChildren(newVal);
      await this.getRoles(newVal);
      if (this.numberChildren > 0) {
        await this.getSubModulesByModule(newVal);
      }
    },
    async subModule(newVal) {
      this.itemArr.index === null
        ? (this.itemArr.role = {
            id: 0,
            name: "Select a role",
          })
        : this.itemArr.role;
      await this.getRoles(newVal);
    },
    "userData.email": async function () {
      const regExp = /^[a-zA-Z][-_.a-zA-Z0-9]{4,29}@alarcongroup.us$/;
      if (
        this.userData.email.match(regExp) &&
        this.userData.email !== this.userData.checkEmail
      ) {
        const resp = await amgApi.post("/commons/valid-email", {
          r_email: this.userData.email,
        });
        this.isValidEmail = resp.data.length > 0;
      } else {
        this.isValidEmail = false;
      }
    },
    "userData.newarr": function () {
      if (this.userData.admin === 2) {
        this.isNoElement = this.userData.newarr.length <= 0;
      } else {
        this.isNoElement = false;
      }
    },
  },
  async mounted() {
    this.currentUserActual = this.currentUser.user_id;
    await Promise.all([this.getAllDocuments(), this.getAllDepartments()]);
    this.finishedLoading = true;
  },
  methods: {
    async validateByDNI() {
      const dniRegex = /^\d{8}$/; // Expresión regular para verificar 8 dígitos consecutivos

      if (!dniRegex.test(this.userData.pid)) {
        // Si no contiene 8 dígitos, muestra un mensaje de error
        this.showWarningSwal(
          "Invalid DNI?",
          "The DNI must contain exactly 8 digits."
        );
        return;
      }

      try {
        const params = {
          dni: this.userData.pid,
        };

        this.addPreloader();
        const response = await EmployeesService.getDataByDni(params);

        this.userData.first_name = response.data.data.nombres;
        this.userData.last_name = `${response.data.data.apellido_paterno} ${response.data.data.apellido_materno}`;

        this.removePreloader();
      } catch (error) {
        this.removePreloader();
        this.showWarningSwal(
          "Invalid DNI?",
          "The DNI does not exist or has an error. Check the number and Try again"
        );
      }
    },

    async getAllDocuments() {
      try {
        const data = await EmployeesService.getAllDocuments();

        // eslint-disable-next-line no-param-reassign

        this.document = data.data;
        this.optionsTypeDocument = data.data;
        // Must return an array of items or an empty array if an error occurred

        return this.document;
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      }
    },
    async getAllDepartments() {
      try {
        const data = await EmployeesService.getAllDepartments();

        // eslint-disable-next-line no-param-reassign

        this.department = data.data;
        // Must return an array of items or an empty array if an error occurred

        this.getRolByDepartment();

        return this.department;
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      }
    },

    async getRolByDepartment() {
      try {
        const params = {
          id: this.userData.department_id,
        };
        const data = await EmployeesService.getRolByDepartment(params);

        // eslint-disable-next-line no-param-reassign

        this.optionsRol = data.data;
        // Must return an array of items or an empty array if an error occurred

        // this.userData.role = null;
        // this.userData.rol_id = null;
        return this.optionsRol;
      } catch (e) {
        this.showErrorSwal(e);
        return [];
      }
    },
    changeCreditExpertAdmin() {
      if (this.userData.creditExpertAdmin === 1) {
        this.itemArr.role.id = 17;
        this.itemArr.role.name = "Chief";
      } else {
        this.itemArr.role.id = 0;
        this.itemArr.role.name = "Select a role";
      }
    },
    avatarText(fullName) {
      return avatarText(fullName);
    },
    async checkIfActiveOrInactive() {
      if (this.$route.params.id) {
        if (this.userData.status != 1) {
          await this.inactivate();
        }
      }
    },
    showConfirmSwalForUsers(
      title = "Are you sure?",
      text = "You won't be able to revert this!",
      config = {}
    ) {
      return this.$swal({
        title,
        text,
        imageUrl: swalWarningIcon,
        imageWidth: 70,
        showCancelButton: true,
        buttonsStyling: false,
        showDenyButton: true,
        denyButtonText: "Later",
        confirmButtonText: "Assign Now",
        customClass: {
          confirmButton: "btn btn-primary mr-1",
          cancelButton: "btn btn-outline-danger",
          denyButton: "btn btn-info mr-1",
        },
        ...config,
      });
    },
    async inactivate() {
      try {
        const params = {
          id: this.userData.id,
        };
        const response = await UsersService.getCountClientsOfAdvisor(params);
        if (response[0].cantidad > 0) {
          const confirm = await this.showConfirmSwalForUsers(
            "The advisor has assigned clients, you should assign them to another advisor to be able to inactivate the advisor",
            "You can also do it later"
          );
          if (confirm.isConfirmed) {
            this.dataItem = {
              user_id: this.userData.id,
              user_name: `${this.userData.first_name} ${this.userData.last_name}`,
            };
            this.modalClientsAssignedState = true;
            this.userData.status = this.userData.status === 1 ? 0 : 1;
          } else if (confirm.isDenied) {
            this.userData.status = this.userData.status;
          } else if (confirm.isDismissed) {
            this.userData.status = this.userData.status === 1 ? 0 : 1;
          }
        }
      } catch (e) {
        console.error(e);
        this.showToast(
          "danger",
          "top-right",
          "Error",
          "XIcon",
          "Something went wrong!"
        );
        this.removePreloader();
      }
    },
    addFile() {
      this.$refs.refInputEl.$el.childNodes[0].click();
    },
    fillFileData(event) {
      const image = event.srcElement.files[0];
      this.form.image = image.name;

      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = () => (this.form.file = reader.result);

      this.form.remove = true;
    },
    clearFileData() {
      this.form.file = "";
      this.form.image = "";
      this.form.remove = false;
      this.$refs.refInputEl.reset();
    },
    deleteRelation(index) {
      this.userData.newarr.splice(index, 1);
    },
    clearItemArr() {
      this.itemArr = {
        role: { id: 0, name: "Select a role" },
        module: { id: 0, name: "Select a module" },
        sub_module: { id: 0, name: "Select a sub module" },
        typesenior: 0,
        social_network: 0,
        index: null,
      };
    },
    addRelation(item) {
      if (
        item.module.id === 0 ||
        item.role.id === 0 ||
        (((item.module.id === 2 && item.role.id === 5) ||
          (item.module.id === 15 && item.role.id === 9) ||
          item.role.id === 10) &&
          item.typesenior === 0) ||
        (item.sub_module.parent_id === 21 &&
          item.role.id === 15 &&
          this.userData.skill === 0) ||
        (item.module.id !== 0 &&
          item.role.id !== 0 &&
          item.role.id !== 17 &&
          item.sub_module.id === 0 &&
          this.numberChildren > 0 &&
          item.module.id === 21) ||
        this.validationEspecialist(item)
      ) {
        this.isModuleRole = true;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "There's data you need to complete",
            icon: "AlertCircleIcon",
            variant: "danger",
          },
        });
      } else {
        this.isModuleRole = false;
        const moduleOrSubmodule =
          this.numberChildren > 0 && item.role.id !== 17
            ? item.sub_module
            : item.module;
        const newItem = {
          id_module: moduleOrSubmodule.id,
          module: moduleOrSubmodule.name,
          parent_id: moduleOrSubmodule.parent_id,
          id_role: item.role.id,
          role: item.role.name,
          typesenior: item.typesenior,
        };
        if (item.index === null) {
          if (this.userData.newarr.length === 0) {
            this.userData.newarr.push(newItem);
            this.userData.social_network =
              newItem.id_module === 2 && newItem.id_role === 5
                ? this.itemArr.social_network
                  ? 1
                  : 0
                : 0;
            this.clearItemArr();
          } else {
            const filterData = this.userData.newarr.find(
              (data) => data.id_module === newItem.id_module
            );
            if (filterData == undefined) {
              this.userData.newarr.push(newItem);
              this.userData.social_network =
                newItem.id_module === 2 && newItem.id_role === 5
                  ? this.itemArr.social_network
                    ? 1
                    : 0
                  : this.userData.social_network;
              this.clearItemArr();
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Module already exists",
                  icon: "AlertCircleIcon",
                  variant: "danger",
                },
              });
            }
          }
        } else {
          this.userData.social_network =
            newItem.id_module === 2 && newItem.id_role === 5
              ? this.itemArr.social_network
                ? 1
                : 0
              : this.userData.social_network;
          this.userData.newarr[item.index] = newItem;
          this.userData.newarr = JSON.parse(
            JSON.stringify(this.userData.newarr)
          );
          this.clearItemArr();
        }
      }
    },
    updateRelation(data) {
      this.itemArr = {
        role: { id: data.item.id_role, name: data.item.role },
        module: { id: data.item.id_module, name: data.item.module },
        typesenior: data.item.typesenior,
        social_network: this.userData.social_network === 1,
        index: data.index,
      };
    },
    async getRoles(modules) {
      this.roles = await EditService.loadRolesByModule(modules);
    },
    async getSubModulesByModule(modules) {
      this.subModules = await EditService.getSubModulesByModule(modules);
    },
    async getModuleNumberChildren(modules) {
      const data = await EditService.getModuleNumberChildren(modules);
      this.numberChildren = data[0].cc;
    },
    toggleAll(checked) {
      const data = [...(checked ? this.programsOptions : [])];
      data.forEach((element, i) => {
        data[i] = { id: element.id };
      });
      this.userData.program = [...data];
    },
    closeModalChange() {
      this.openModalChange = false;
    },
    closeModalValidatePass() {
      this.openModalValidatePass = false;
    },
    closeModalValidateShow() {
      this.openModalValidateShow = false;
    },
    showModalPassAfterValidate(isOk) {
      this.openModalValidatePass = !isOk;
      this.openModalChange = isOk;
    },
    showPasswordUser(isOk, password) {
      if (password) {
        this.getPasswordEditUser = password;
      }
      this.openModalValidateShow = !isOk;
      this.showPasswordPublic = isOk;
    },

    validationEspecialist({ module, role, sub_module }) {
      return (
        module.id !== 0 &&
        role.id !== 0 &&
        role.id !== 17 &&
        sub_module.id === 0 &&
        this.numberChildren > 0 &&
        module.id === 11
      );
    },

    validationEspecialist({ module, role, sub_module }) {
      return (
        module.id !== 0 &&
        role.id !== 0 &&
        role.id !== 17 &&
        sub_module.id === 0 &&
        this.numberChildren > 0 &&
        module.id === 11
      );
    },

    formatTypeSenior(type, isConnection = false) {
      switch (type) {
        case 1:
          if (isConnection) {
            return "Trainer";
          }
          return "Begginer";
        case 2:
          return "Junior";
        case 3:
          return "Senior";
        case 4:
          return "Master";
      }
    },
  },
};
</script>

<style lang="scss">
.avatar-container {
  position: relative;

  .remove-temporal-avatar {
    display: none;
    transition: all 0.5s ease-in-out;
  }

  &.backdrop:hover {
    &::after {
      position: absolute;
      content: "";
      background: grey;
      width: 100%;
      height: 100%;
      opacity: 0.5;
      z-index: 10;
      top: 0px;
      left: 0px;
    }

    .remove-temporal-avatar {
      cursor: pointer;
      position: absolute;
      z-index: 100;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      display: flex !important;
      justify-content: center;
      align-items: center;

      div {
        width: 45px;
        height: 45px;
        background: transparent !important;
        border: 3px white solid;
        outline: none;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        z-index: 100;
      }
    }
  }
}
.checks-boxes {
  display: grid;
  grid-template-columns: auto auto auto;
}
.is-border-danger {
  border: 1px solid red !important;
  border-radius: 0px 4px 4px 0px !important;
}
@media screen and(max-width: 1200px) and (min-width: 968px) {
  .information-form {
    margin-top: 30px;
  }
}
</style>
